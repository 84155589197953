import { mapHelper } from 'common/utils'

// 方法
const method = [
  {
    text: 'get',
    value: 'GET'
  },
  {
    text: 'post',
    value: 'POST'
  },
  {
    text: 'delete',
    value: 'DELETE'
  },
  {
    text: 'put',
    value: 'PUT'
  }
]

const {
  map: methodMap,
  setOps: setMethodOps
} = mapHelper.setMap(method)

// 是否关联菜单
const hasMenuRelated = [
  {
    text: '是',
    value: true
  },
  {
    text: '否',
    value: false
  }
]

const {
  map: hasMenuRelatedMap,
  setOps: setHasMenuRelatedOps
} = mapHelper.setMap(hasMenuRelated)

// 权限校验
const interceptEnable = [
  {
    text: '关闭',
    value: 0
  },
  {
    text: '正常',
    value: 1
  }
]

const {
  map: interceptEnableMap,
  setOps: setInterceptEnableOps
} = mapHelper.setMap(interceptEnable)

// appType类型
const appType = [
  {
    text: '业主端App',
    value: 1
  },
  {
    text: '物管App',
    value: 2
  },
  {
    text: '管理后台',
    value: 10
  }
]

const {
  map: appTypeMap,
  setOps: appTypeOps
} = mapHelper.setMap(appType)

export {
  methodMap,
  setMethodOps,
  hasMenuRelatedMap,
  setHasMenuRelatedOps,
  interceptEnableMap,
  setInterceptEnableOps,
  appTypeMap,
  appTypeOps
}
