// 查询列表
const queryListURL = `${API_CONFIG.controlBaseURL}resource/list`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}resource/export`

// 导入Excel
const importURL = `${API_CONFIG.controlBaseURL}resource/importExcel`

// 新增
const createURL = `${API_CONFIG.controlBaseURL}resource/add`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}resource/update`
// 查询详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}resource/get`
// 所属平台/上级菜单 select2
const queryMenuListURL = `${API_CONFIG.controlBaseURL}menu/select2`
// 菜单名称 select2
const selectSubMenuURL = `${API_CONFIG.controlBaseURL}menu/selectSubMenu`
// 搜索-资源所属平台字段
const getResourceAppType = `${API_CONFIG.controlBaseURL}resource/getResourceAppType`

export {
  queryListURL,
  exportListURL,
  getResourceAppType,
  importURL,
  createURL,
  editURL,
  queryDetailURL,
  queryMenuListURL,
  selectSubMenuURL
}
