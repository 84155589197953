var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ResourceForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息：" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "URL",
                        rules: [
                          {
                            required: true,
                            message: "请输入URL",
                            trigger: "change",
                          },
                        ],
                        prop: "url",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入URL",
                          maxlength: 100,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "url", $$v)
                          },
                          expression: "form.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "方法",
                        rules: [
                          {
                            required: true,
                            message: "请选择方法",
                            trigger: "change",
                          },
                        ],
                        prop: "method",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.methodOps, width: _vm.width },
                        model: {
                          value: _vm.form.method,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "method", $$v)
                          },
                          expression: "form.method",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "权限校验",
                        rules: [
                          {
                            required: true,
                            message: "请选择权限校验",
                            trigger: "change",
                          },
                        ],
                        prop: "interceptEnable",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.interceptEnableOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.interceptEnable,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "interceptEnable", $$v)
                          },
                          expression: "form.interceptEnable",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "资源所属平台",
                        rules: [
                          {
                            required: true,
                            message: "请选择资源所属平台",
                            trigger: "change",
                          },
                        ],
                        prop: "interceptEnable",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.appRequestTypeOps,
                          width: _vm.typeWidth,
                        },
                        model: {
                          value: _vm.form.appType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appType", $$v)
                          },
                          expression: "form.appType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        rules: [
                          {
                            required: true,
                            message: "请输入对资源的描述",
                            trigger: "change",
                          },
                        ],
                        prop: "remarks",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入对资源的描述",
                          maxlength: 300,
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.statusOps.length > 0
                ? _c(
                    "col2-block",
                    { attrs: { title: "脱敏设置：" } },
                    [
                      _vm._l(
                        _vm.form.desensitizationList,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "脱敏字段" } },
                                [
                                  _c("v-input", {
                                    staticClass: "first-margin",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: 30,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: item.fieldName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "fieldName", $$v)
                                      },
                                      expression: "item.fieldName",
                                    },
                                  }),
                                  _vm.form.desensitizationList.length !== 1
                                    ? _c("v-button", {
                                        staticClass: "button",
                                        attrs: { type: "danger", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addOrDeleteAction(
                                              false,
                                              index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "脱敏规则" } },
                                [
                                  _c("v-select", {
                                    attrs: { options: _vm.statusOps },
                                    model: {
                                      value: item.code,
                                      callback: function ($$v) {
                                        _vm.$set(item, "code", $$v)
                                      },
                                      expression: "item.code",
                                    },
                                  }),
                                  item.code !== "custom"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "例：" +
                                              _vm._s(
                                                _vm.statusOps[
                                                  _vm.findIndex(item.code)
                                                ].desc
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              item.code === "custom"
                                ? _c("el-form-item", [
                                    _c(
                                      "div",
                                      { staticClass: "input-number-group" },
                                      [
                                        _c("v-input-number", {
                                          attrs: {
                                            label: "显示前",
                                            placeholder: "请输入",
                                            width: _vm.numberWidth,
                                            max: _vm.maxNumberCount,
                                          },
                                          model: {
                                            value: item.prefixCount,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prefixCount", $$v)
                                            },
                                            expression: "item.prefixCount",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "number-input-unit" },
                                          [_vm._v("位")]
                                        ),
                                        _c("v-input-number", {
                                          staticClass: "number-input",
                                          attrs: {
                                            label: "显示后",
                                            placeholder: "请输入",
                                            width: _vm.numberWidth,
                                            max: _vm.maxNumberCount,
                                          },
                                          model: {
                                            value: item.suffixCount,
                                            callback: function ($$v) {
                                              _vm.$set(item, "suffixCount", $$v)
                                            },
                                            expression: "item.suffixCount",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "number-input-unit" },
                                          [_vm._v("位")]
                                        ),
                                        _c("v-input-number", {
                                          staticClass: "number-input",
                                          attrs: {
                                            label: "显示*字符",
                                            placeholder: "请输入",
                                            width: _vm.numberWidth,
                                            max: _vm.maxNumberCount,
                                          },
                                          model: {
                                            value: item.middleCount,
                                            callback: function ($$v) {
                                              _vm.$set(item, "middleCount", $$v)
                                            },
                                            expression: "item.middleCount",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "number-input-unit" },
                                          [_vm._v("位")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "20px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "显示*字符 0 位表示除显示的前 m 位和后 n 位外，中间的部分全部为 *"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("v-button", {
                            attrs: { text: "新增" },
                            on: {
                              click: function ($event) {
                                return _vm.addOrDeleteAction(true, 0)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "col2-block",
                { attrs: { title: "资源关联：" } },
                _vm._l(_vm.form.relatedMenuIds, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上级菜单",
                            rules: [
                              {
                                required: false,
                                message: "请选择上级菜单",
                                trigger: "change",
                              },
                            ],
                            prop: "relatedMenuIds." + index + ".parentId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                key: "resourceSelect2ParentId_" + item.id,
                                attrs: {
                                  subjoin: _vm.parentMenuSubjoin(item),
                                  placeholder: "查询上级菜单",
                                  width: _vm.width,
                                },
                                model: {
                                  value: item.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "parentId", $$v)
                                  },
                                  expression: "item.parentId",
                                },
                              },
                              "v-select2",
                              _vm.parentMenuParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "菜单名称",
                            rules: [
                              {
                                required: false,
                                message: "请选择菜单名称",
                                trigger: "change",
                              },
                            ],
                            prop: "relatedMenuIds." + index + ".menuId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                key: "resourceSelect2MenuId_" + item.id,
                                staticClass: "inline-block",
                                attrs: {
                                  subjoin: _vm.menuSubjoin(item),
                                  disabled: !item.parentId,
                                  placeholder: "查询菜单名称",
                                  width: _vm.width,
                                },
                                model: {
                                  value: item.menuId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "menuId", $$v)
                                  },
                                  expression: "item.menuId",
                                },
                              },
                              "v-select2",
                              _vm.menuParams,
                              false
                            )
                          ),
                          _vm.form.relatedMenuIds.length > 1
                            ? _c("v-button", {
                                staticClass: "button",
                                attrs: { type: "danger", text: "删除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reduceData(index)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.form.relatedMenuIds.length < 10 &&
                          index === _vm.form.relatedMenuIds.length - 1
                            ? _c("v-button", {
                                staticClass: "button",
                                attrs: { text: "增加" },
                                on: { click: _vm.increaseData },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }