<template>
  <div class="ResourceForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>

        <col2-block title="基础信息：">

          <el-form-item label="URL" :rules="[{ required: true, message: '请输入URL', trigger: 'change' }]" prop="url">
            <v-input v-model="form.url" placeholder="请输入URL" :maxlength="100" :width="width" />
          </el-form-item>

          <el-form-item label="方法" :rules="[{ required: true, message: '请选择方法', trigger: 'change' }]" prop="method">
            <v-select v-model="form.method" :options="methodOps" :width="width" />
          </el-form-item>

          <el-form-item label="权限校验" :rules="[{ required: true, message: '请选择权限校验', trigger: 'change' }]" prop="interceptEnable">
            <v-select v-model="form.interceptEnable" :options="interceptEnableOps" :width="width" />
          </el-form-item>

          <!-- OS-2391 战疫B端集成到物管APP 要求新增所属App-->
          <el-form-item label="资源所属平台" :rules="[{ required: true, message: '请选择资源所属平台', trigger: 'change' }]" prop="interceptEnable">
            <v-select v-model="form.appType" :options="appRequestTypeOps" :width="typeWidth" />
          </el-form-item>

          <el-form-item label="备注" :rules="[{ required: true, message: '请输入对资源的描述', trigger: 'change' }]" prop="remarks">
            <v-textarea v-model="form.remarks" placeholder="请输入对资源的描述" :maxlength="300" />
          </el-form-item>
        </col2-block>

        <col2-block v-if="statusOps.length > 0" title="脱敏设置：">

          <div v-for="(item, index) in form.desensitizationList"
               :key="index">
            <el-form-item  label="脱敏字段">
              <v-input
                class="first-margin"
                placeholder="请输入"
                v-model="item.fieldName"
                :maxlength="30"
                :width="width"
              ></v-input>
              <v-button
                class="button"
                v-if="form.desensitizationList.length !== 1"
                type="danger"
                text="删除"
                @click="addOrDeleteAction(false, index)"
              ></v-button>
            </el-form-item>
            <el-form-item label="脱敏规则">
              <v-select v-model="item.code" :options="statusOps" ></v-select>
              <span  v-if="item.code !== 'custom'" style="margin-left: 20px;">例：{{statusOps[findIndex(item.code)].desc}}</span>
            </el-form-item>
            <el-form-item v-if="item.code === 'custom'">
              <div class="input-number-group">
                <v-input-number label="显示前" placeholder="请输入" v-model="item.prefixCount" :width="numberWidth" :max="maxNumberCount"/>
                <span class="number-input-unit">位</span>
                <v-input-number class="number-input" label="显示后" placeholder="请输入" v-model="item.suffixCount"  :width="numberWidth" :max="maxNumberCount"/>
                <span class="number-input-unit">位</span>
                <v-input-number class="number-input" label="显示*字符" placeholder="请输入" v-model="item.middleCount"  :width="numberWidth" :max="maxNumberCount"/>
                <span class="number-input-unit">位</span>
                <span style="margin-left: 20px;">显示*字符 0 位表示除显示的前 m 位和后 n 位外，中间的部分全部为 *</span>
              </div>
            </el-form-item>
          </div>
          <el-form-item>
            <v-button
              text="新增"
              @click="addOrDeleteAction(true, 0)"
            ></v-button>
          </el-form-item>
        </col2-block>

        <col2-block title="资源关联：">

          <div v-for="(item, index) in form.relatedMenuIds" :key="item.id">

            <!-- <el-form-item label="所属平台" :rules="[{ required: true, message: '请选择所属平台', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.type'">
              <v-select2 v-model="item.type" :key="'resourceSelect2Type_' + item.id" v-bind="typeParams" placeholder="查询所属平台" :width="width" />
            </el-form-item> -->

            <el-form-item label="上级菜单" :rules="[{ required: false, message: '请选择上级菜单', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.parentId'">
              <v-select2 v-model="item.parentId" :key="'resourceSelect2ParentId_' + item.id" v-bind="parentMenuParams" :subjoin="parentMenuSubjoin(item)" placeholder="查询上级菜单" :width="width" />
            </el-form-item>

            <el-form-item label="菜单名称" :rules="[{ required: false, message: '请选择菜单名称', trigger: 'change' }]" :prop="'relatedMenuIds.' + index + '.menuId'">
              <v-select2 class="inline-block" v-model="item.menuId" :key="'resourceSelect2MenuId_' + item.id" v-bind="menuParams" :subjoin="menuSubjoin(item)" :disabled="!item.parentId" placeholder="查询菜单名称" :width="width" />
              <v-button class="button" v-if="form.relatedMenuIds.length > 1" type="danger" text="删除" @click="reduceData(index)" />
              <v-button class="button" v-if="form.relatedMenuIds.length < 10 && index === form.relatedMenuIds.length - 1" text="增加" @click="increaseData" />
            </el-form-item>

          </div>

        </col2-block>

      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
  import {createURL, getResourceAppType, editURL, queryDetailURL, queryMenuListURL, selectSubMenuURL} from './api'
  import { sysCodewordUrl } from 'common/api'
  import {appTypeMap, setInterceptEnableOps, setMethodOps} from './map'
  import {Col2Block, Col2Detail} from 'components/bussiness'

  export default {
  name: 'ResourceForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 400,
      typeWidth: 200,
      numberWidth: 80,
      maxNumberCount: 9,
      sysCodewordUrl,
      methodOps: setMethodOps(),
      interceptEnableOps: setInterceptEnableOps(),
      appRequestTypeOps: [],
      typeParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        },
        subjoin: {
          menuLevel: '-1'
        }
      },
      parentMenuParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      menuParams: {
        searchUrl: selectSubMenuURL,
        method: 'POST',
        contentType: 'application/json',
        request: {
          text: 'menuName',
          value: 'menuId'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      firstDesensitizationCode:undefined,
      form: {
        id: undefined,
        url: '',
        method: undefined,
        remarks: '',
        interceptEnable: undefined,
        relatedMenuIds: [],
        appType: undefined,
        desensityRule: '',
        desensitizationList:[]
      },
      submitConfig: {
        submitMethod: '',
        submitUrl: '',
        queryUrl: ''
      },
      statusOps: []
    }
  },
    created() {
      this.getDesensitizationRule()
      this.getAppRequestTypeOps()
    },
    mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑资源')
      this.submitConfig.submitMethod = 'PUT'
      this.submitConfig.submitUrl = editURL
      this.submitConfig.queryUrl = `${queryDetailURL}/${id}`
      this.$refs.formPanel.getData()
    } else {
      this.$setBreadcrumb('新增资源')
      this.submitConfig.submitMethod = 'POST'
      this.submitConfig.submitUrl = createURL
      this.form.method = this.methodOps[0].value
      this.form.interceptEnable = this.interceptEnableOps[0].value
      this.form.appType = this.appRequestTypeOps[0].value
      this.increaseData()
    }
  },
  methods: {
    findIndex (value) {
      return this.statusOps.findIndex((option) => {
        return option.value === value
      })
    },
    createId () {
      return new Date().valueOf().toString()
    },
    increaseData () {
      this.form.relatedMenuIds.push({
        id: this.createId()
      })
    },
    reduceData (index) {
      this.form.relatedMenuIds.splice(index, 1)
    },
    parentMenuSubjoin (item) {
      return {
        topParentId: item.type,
        menuType: 2,
        specialNo: this.form.appType === 2 ? 'wgMenuFirstMenu' : ''
      }
    },
    menuSubjoin (item) {
      return {
        parentId: item.parentId,
        menuType: 3
      }
    },
    update (data) {
      if (data) {
        data.appType = parseInt(data.appType)
      }
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
      if (data.relatedMenus && data.relatedMenus.length < 1) {
        this.increaseData()
      } else {
        if (data.relatedMenus && data.relatedMenus.length) {
          data.relatedMenus.forEach((item, index) => {
            item['id'] = this.createId() + index
          })
        }
        this.form.relatedMenuIds = data.relatedMenus
      }

      if(data.desensityRule){
        this.form.desensitizationList = JSON.parse(data.desensityRule)
      }
    },
    submitBefore (data) {
      if(this.getRepeatName().length > 0){
        this.$message({
          message: '同一个脱敏字段不能重复填写: '+this.getRepeatName().join(','),
          center: true
        })
        return false
      }
      const relatedMenuIds = []
      data['relatedMenuIds'].forEach(item => {
        if (item.menuId) {
          relatedMenuIds.push(item.menuId)
        }
      })
      data['relatedMenuIds'] = relatedMenuIds
      // 过滤掉字段名为空的item
      data.desensityRule = JSON.stringify(data.desensitizationList.filter(item => {
        return item.fieldName
      }))
      delete data.desensitizationList
      let dataObject = `${data.url}-${appTypeMap[data.appType]}-${data.method}`
      this.$set(data, 'dataObject', dataObject)
      return true
    },
    addOrDeleteAction (add, index) {
      if (add) {
        // 添加
        if (this.form.desensitizationList.length < 10) {
          this.form.desensitizationList.push({
            fieldName: '',
            code: this.firstDesensitizationCode
          })
        } else {
          this.$message({
            type: 'error',
            message: '最多可添加10个字段',
            center: true
          })
        }
      } else {
        // 减少
        this.form.desensitizationList.splice(index, 1)
      }
    },
    // 获取重复的脱敏字段
     getRepeatName () {
      let repeatArr = []
      this.form.desensitizationList.forEach(item1 =>{
        // 这里加上判空，是为了过滤两个空的字段名比较
        if(item1.fieldName){
          let i = 0;
          this.form.desensitizationList.forEach(item2 =>{
            if(item2.fieldName && item2.fieldName === item1.fieldName){
              i++;
            }
          })
          if( i > 1){
            repeatArr.push(item1.fieldName)
          }
        }
      })
      return _.uniq(repeatArr)
    },
    // 获取脱敏规则
    getDesensitizationRule () {
      let _this = this
      this.$axios({
        method: 'GET',
        url: sysCodewordUrl,
        params: { typeValue: 'DESENSITIZATION_ROLE_TYPE' }
      }).then(res => {
        if(res.status === 100 && res.data){
          _this.statusOps = []
           res.data.forEach(item => {
            _this.statusOps.push({
              text:item.value,
              value:item.code,
              desc:item.remark
            })
          })
          if(res.data.length > 0){
            _this.firstDesensitizationCode = res.data[0].code
            _this.form.desensitizationList.length <= 0 && _this.form.desensitizationList.push({
              fieldName : '',
              code: res.data[0].code
            })
          }
        }
      })
    },
    getAppRequestTypeOps () {
      let _this = this
      this.$axios({
        method: 'GET',
        url: getResourceAppType
      }).then(res => {
        if(res.status === 100 && res.data){
          let list = res.data || []
          _this.appRequestTypeOps = list.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          }) || []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ResourceForm {
  .inline-block {
    display: inline-block;
  }
  .button {
    margin-left: 20px;
  }
  .input-number-group{
    display: flex;
    .number-input {
      margin-left: 20px;
    }
    .number-input-unit {
      margin-left: 5px;
    }
  }
}
</style>
